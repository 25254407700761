import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,4,5,6];

export const dictionary = {
		"/auth/auth-code-error": [7,[3]],
		"/auth/login": [8,[3]],
		"/auth/reset-password": [~9,[3]],
		"/auth/reset-password/reset-confirm": [10,[3]],
		"/dashboard": [~11,[4]],
		"/dashboard/applications": [12,[4]],
		"/dashboard/builder": [13,[4]],
		"/dashboard/chat": [14,[4]],
		"/dashboard/community": [~15,[4]],
		"/dashboard/feedback": [16,[4]],
		"/dashboard/profile": [17,[4,5]],
		"/dashboard/profile/advice": [18,[4,5]],
		"/dashboard/profile/build": [19,[4,5]],
		"/dashboard/profile/download": [~20,[4,5]],
		"/dashboard/profile/values": [21,[4,5]],
		"/dashboard/recommended": [~22,[4]],
		"/dashboard/search": [~23,[4]],
		"/dashboard/settings": [24,[4]],
		"/public/accreditations": [~25,[6]],
		"/public/accreditations/[id]": [~26,[6]],
		"/public/accreditations/[id]/companies": [~27,[6]],
		"/public/accreditations/[id]/jobs": [~28,[6]],
		"/public/benefits": [~29,[6]],
		"/public/benefits/[id]": [~30,[6]],
		"/public/benefits/[id]/companies": [~31,[6]],
		"/public/benefits/[id]/jobs": [~32,[6]],
		"/public/companies": [~33,[6]],
		"/public/companies/search": [34,[6]],
		"/public/company": [~35,[6]],
		"/public/company/[companyId]": [~36,[6]],
		"/public/company/[companyId]/jobs": [~37,[6]],
		"/public/company/[companyId]/jobs/[postId]": [~38,[6]],
		"/public/integrations": [~39,[6]],
		"/public/job-bias-checker-free": [40,[6]],
		"/public/jobs": [~41,[6]],
		"/public/search": [42,[6]],
		"/public/templates": [~43,[6]],
		"/public/templates/cv": [44,[6]],
		"/public/values": [~45,[6]],
		"/public/values/[id]": [~46,[6]],
		"/public/values/[id]/accreditations": [~47,[6]],
		"/public/values/[id]/companies": [~48,[6]],
		"/public/values/[id]/jobs": [~49,[6]],
		"/sentry-example": [50]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';