import 'shared-lib/types/supabase';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';

// Initialize the Sentry SDK here
Sentry.init({
  dsn: 'https://ce597d0028bf270657738dcc0603d714@o4505642762108928.ingest.sentry.io/4505642766237696',
  integrations: [],
  // integrations: [new Sentry.Replay()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['Non-Error promise rejection captured'],
  enabled: PUBLIC_ENV === 'prod',
  environment: PUBLIC_ENV
});
